import $ from "jquery";

//part of library

function playAnimation($p, animname, options)
{
	if(!options) {
		options = {};
	}
	$p.off("animationend").one("animationend", function() {
		$p.css("animation-name", "");
		if(options.hideOnEnd) {
			if($p.hasClass("visible")) {
				$p.removeClass("visible");
				$p.addClass("hidden");
			} else {
				$p.hide();
			}
		}
		if(options.callback) {
			options.callback($p);
		}
	});
	if(options.forceRestart) {
		$p.css("animation-name", "");
		$p.get(0).offsetHeight; //trigger reflow
	}
	$p.css("animation-name", animname);
	if(options.showOnStart) {
		if($p.hasClass("hidden")) {
			$p.removeClass("hidden");
			$p.addClass("visible");
		} else {
			$p.show();
		}
	}
}

function switchMenuContent($menu, $li) {
    const firstLevel = !$li || $li.length == 0;

    $menu.find("a").hide();
    let $visibleA;
    if(firstLevel) {
        $visibleA = $menu.find("li.level1 > a");
    } else {
        $visibleA = $li.children("ul").children("li").children("a");
    }

    $visibleA.show();
    $menu.find(".backlink").toggle(!firstLevel);

    $menu.find("li").removeClass("pageselected");
    if($li) {
        $li.addClass("pageselected");
    }
}

function switchMenuContentBack($menu) {
    const $currentLi = $menu.find("li.pageselected").last();
    if($currentLi.length > 0) {
        switchMenuContent($menu, $currentLi.parent().closest("li"));
    }
}

function initPagedMenu($menu) {
    //Default page
    switchMenuContent($menu, null);

    //Auto switch to current active link/page
    const $activeLi = $menu.find("li.currentpage").last();
    const $parentLi = $activeLi.parent().closest("li");
    if($parentLi.length > 0) {
        switchMenuContent($menu, $parentLi);
    }
    
    //Forward links to subpage
    $menu.on("click", "li.hassubmenu > a", function(ev) {
        ev.preventDefault(); //do not open URL

        const $a = $(this);
        const $li = $a.closest("li");
    
        playAnimation($menu, "pagedmenu_disappear_left", {
            "callback": function() {
                switchMenuContent($menu, $li);
                playAnimation($menu, "pagedmenu_appear_left");
            }
        });
    });

    //Back link
    $menu.find(".backlink").on("click", function() {
        playAnimation($menu, "pagedmenu_disappear_right", {
            "callback": function() {
                switchMenuContentBack($menu);
                playAnimation($menu, "pagedmenu_appear_right");
            }
        });
    });
}

$(function() {
    $(".pagedmenu").each(function() {
        const $menu = $(this);
        initPagedMenu($menu);
    });
});